import * as React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import { useConfig } from "gatsby-theme-advanced";

import Layout from "../gatsby-theme-amaranth/layouts";

const AboutPage = (): JSX.Element => {
  const config = useConfig();

  const Wrapper = styled.main`
  width: 100%;
  max-width: calc(1144px + 2 * 16px);
  word-wrap: break-word;
  word-break: break-word;
  display: grid;
  grid-gap: 24px;
  // justify-items: center;
  margin: 0 auto;
  padding: 0 1em;
`;

  return (
    <Layout>
      <div className="about-container">
        <Helmet title={`About | ${config.website.title}`} />

        <Wrapper>
          <h1>About</h1>

          <p>This is a place to highlight inclusive greetings cards.</p>
        </Wrapper>
      </div>
    </Layout>
  );
};

export default AboutPage;
